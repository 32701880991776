import React, { FC, useMemo } from 'react';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import { NonNullable } from 'types';
import { Table } from 'components/table';
import { isTruthy, getStaticTableColumns } from 'utils';
import '__generated__/gatsby-types';
import './infoTable.css';
  
const messages = defineMessages({
  roomType: {
    id: 'room.room_type',
    defaultMessage: 'Kambario tipas'
  },
  ammenities: {
    id: 'room.amenities',
    defaultMessage: 'Patogumai'
  },
  prices: {
    id: 'room.prices',
    defaultMessage: 'Nuomos kainos'
  },
  arrivalLabel: {
    id: 'room.arrival_label',
    defaultMessage: 'Priėmimas'
  },
  departureLabel: {
    id: 'room.departure_label',
    defaultMessage: 'Išvykimas'
  },
  arrivalValue: {
    id: 'room.arrival_value',
    defaultMessage: 'nuo 14 val.'
  },
  departureValue: {
    id: 'room.departure_value',
    defaultMessage: 'iki 12 val.'
  },
  kitchen: {
    id: 'room.amenities.kitchen',
    defaultMessage: 'Virtuvė'
  },
  privateBathroom: {
    id: 'room.amenities.private_bathroom',
    defaultMessage: 'privatus dušas ir WC'
  },
  sharedBathroom: {
    id: 'room.amenities.shared_bathroom',
    defaultMessage: 'bendro naudojimo dušas ir WC'
  },
  cableTv: {
    id: 'room.amenities.cable_tv',
    defaultMessage: 'TV'
  },
  balcony: {
    id: 'room.amenities.balcony',
    defaultMessage: 'balkonas'
  },
  wifi: {
    id: 'room.amenities.wifi',
    defaultMessage: 'Wifi'
  },
  fireplace: {
    id: 'room.amenities.fireplace',
    defaultMessage: 'židinys'
  },
  wideBed: {
    id: 'room.amenities.wide_bed',
    defaultMessage: 'plati lova'
  },
  separateEntrance: {
    id: 'room.amenities.separate_entrance',
    defaultMessage: 'atskiras įėjimas'
  },
  terrace: {
    id: 'room.amenities.terace',
    defaultMessage: 'terasa'
  }
});

const amenitiesLookupById: Record<string, MessageDescriptor> = {
  1: messages.kitchen,
  2: messages.privateBathroom,
  3: messages.sharedBathroom,
  4: messages.cableTv,
  5: messages.balcony,
  6: messages.wifi,
  7: messages.fireplace,
  8: messages.wideBed,
  9: messages.separateEntrance,
  10: messages.terrace
};

interface Props {
  attributes: NonNullable<GatsbyTypes.SingleRoomDataQuery['room']>['roomAttributes'];
}
  
export const InfoTable: FC<Props> = props => {
  const intl = useIntl();
  const rows = [];

  const priceRange = useMemo(() => {
    const allPrices = Object.values(props?.attributes?.prices || {})
      .map(price => [price?.priceFrom, price?.priceTo])
      .flat()
      .filter(isTruthy);

      return allPrices.length 
        ? [Math.min(...allPrices), Math.max(...allPrices)]
        : [];
  }, [props]);


  if (props.attributes?.roomType) {
    rows.push({
      left: intl.formatMessage(messages.roomType),
      right: props.attributes.roomType
    });
  }

  if (props.attributes?.amenities) {
    rows.push({
      left: intl.formatMessage(messages.ammenities),
      right: props.attributes.amenities
        .map(id => id ? intl.formatMessage(amenitiesLookupById[Number(id)]) : null)
        .filter(isTruthy)
        .join(', ')
    });
  }

  rows.push(
    {
      left: intl.formatMessage(messages.arrivalLabel),
      right: intl.formatMessage(messages.arrivalValue)
    },
    {
      left: intl.formatMessage(messages.departureLabel),
      right: intl.formatMessage(messages.departureValue)
    }
  );

  if (priceRange.length) {
    rows.push({
      left: intl.formatMessage(messages.prices),
      right: `${priceRange.join('-')} €`
    });
  }

  const columns = getStaticTableColumns();

  return (
    <Table
      className="room-info w-full"
      showHeader={false}
      responsive={false}
      rows={rows}
      columns={columns}
    />
  );
};