import React, { FC } from 'react';
import { MenuItem, PageLocation } from 'types';
import { Link } from 'gatsby';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { roomsMenuIds, isActiveMenu, getLocalizedPath } from 'utils';

interface Props {
  menuItems: MenuItem[];
  location: PageLocation;
}

export const LeftMenu: FC<Props> = props => {
  const intl = useIntl();
  const roomsMenu = props.menuItems.find(item => roomsMenuIds.includes(item.id));

  const getActiveItemClassName = () => 'before:w-15px before:h-14px before:absolute before:left-0 before:top-0 before:bottom-0 before:m-auto before:bg-menu-icon';

  const renderHeader = () => (
    <h3 className="
      sprite
      rooms-header
      italic
      text-20px
      font-normal
      inline-block
      mb-20px
      relative
      before:inline-block
      before:relative
      before:w-52px
      before:h-20px
      before:bg-left-ornament
      after:inline-block
      after:relative
      after:w-22px
      after:h-9px
      after:bg-right-ornament
    ">
      <span className="
      bg-brown
        inline-block
      text-white
        ml-5px
        mr-5px
        pt-8px
        pb-8px
        pl-15px
        pr-15px
        relative
        z-10
        before:bg-brown-400
        before:left-0
        before:w-full
        before:h-50%
        before:absolute
        before:top-0
        before:-z-10
      ">
        {intl.formatMessage({ 
          id: 'rooms',
          defaultMessage: 'Kambariai'
        })}
      </span>
    </h3>
  );

  const renderMenu = () => roomsMenu && roomsMenu.items.length > 0 ? (
    <ul className="rooms-left-menu m-0 pl-0 pr-5px">
      {roomsMenu.items.map((item, index, arr) => item.path ? (
        <li
          key={item.id}
          className={classNames('relative', 'list-none', {
            'border-t border-t-sand-100': index !== 0,
            'border-b border-b-sand-400': index < arr.length - 1
          })}
        >
          <Link
            to={getLocalizedPath(intl.locale, item.path)}
            className={classNames('sprite text-17px text-dark block pt-8px pb-8px pr-10px pl-20px italic no-underline', {
              [getActiveItemClassName()]: isActiveMenu(item, props.location?.pathname)
            })}
          >
            {item.label}
          </Link>
        </li>
      ) : null)}
    </ul>
  ) : null;


  return (
    <div className="border-r border-sand-400 sticky top-20px">
      <div className="border-r border-sand-100">
        {renderHeader()}
        {renderMenu()}
      </div>
    </div>
  );
};