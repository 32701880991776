import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { defineMessages } from 'react-intl';
import { Gallery } from '@designus/react-simple-gallery/preact';
import { RoomsList } from 'components/roomsList';
import { Layout } from 'components/layout';
import { withIntlProvider } from 'components/withIntl';
import { SingleImage } from 'components/singleImage';
import { FeaturedImage, LayoutRenderProps } from 'types';
import { defaultLanguage, mapGraphqlMediaToGalleryImages, setInnerHtml } from 'utils';
import { InfoWrapper } from './infoWrapper';
import { InfoTable } from './infoTable';
import { LeftMenu } from './leftMenu';
import { SingleRoomProps } from './types';

const messages = defineMessages({
  photoGallery: {
    id: 'room.photo_gallery_title',
    defaultMessage: 'Nuotraukų galerija'
  },
  roomInfo: {
    id: 'room.info_title',
    defaultMessage: 'Informacija apie kambarį'
  },
  otherBedrooms: {
    id: 'room.other_rooms',
    defaultMessage: 'Kiti kambariai'
  }
});

const SingleRoom = (props: SingleRoomProps) => {
  const { room } = props.data;
  const locale = props.pageContext.locale || defaultLanguage;
  const featuredImageNode = room?.featuredImage?.node;

  const featuredImage: FeaturedImage = {
    data: featuredImageNode?.localFile?.childImageSharp?.gatsbyImageData,
    alt: featuredImageNode?.mediaAttribute?.alt?.[locale] || '',
    order: 0
  };

  const images = mapGraphqlMediaToGalleryImages(locale, room?.roomAttributes?.gallery);

  const firstImage = images.length > 0 ? images[0] : null;

  const renderContent = () => !!room?.content && (
    <section
      itemProp="articleBody"
      dangerouslySetInnerHTML={setInnerHtml(room.content)}
    />
  );

  const renderRoomData = (props: LayoutRenderProps) => {
    const { menuItems, location, rooms } = props;
    return (
      <div className="single-room flex justify-between">
        <div className="hidden lg:block lg:w-23%">
          <LeftMenu
            menuItems={menuItems}
            location={location}
          />
        </div>
        <div className="w-full lg:w-75%">
          <h1 className="mb-5 text-3xl">
            {room?.title}
          </h1>
          <div className="mb-20px">
            <SingleImage image={firstImage} />
            {renderContent()}
          </div>
          <InfoWrapper title={messages.roomInfo}>
            <InfoTable attributes={room?.roomAttributes} />
          </InfoWrapper>
          {images.length ? (
            <InfoWrapper title={messages.photoGallery}>
              <Gallery
                images={images}
                transition="fade"
                renderThumbImage={image => (
                  <GatsbyImage
                    image={image.thumb}
                    alt={image.alt || ''}
                  />
                )}
                renderFullImage={image => (
                  <GatsbyImage
                    image={image.full}
                    alt={image.alt || ''}
                    objectFit="contain"
                  />
                )}
              />
            </InfoWrapper>
          ) : null}
          <InfoWrapper
            className="lg:hidden"
            title={messages.otherBedrooms}
          >
            <RoomsList rooms={rooms.filter(otherRoom => otherRoom.id !== room?.id)} />
          </InfoWrapper>
        </div>
      </div>
    );
  };

  return (
    <Layout
      location={props.location}
      featuredImage={featuredImage}
      title={room?.title || ''}
      seoTitle={room?.seo?.title || ''}
      metaDescription={room?.seo?.metaDesc || ''}
      showTitle={false}
      addSidePadding={false}
    >
      {renderRoomData}      
    </Layout>
  );
};

export default withIntlProvider(SingleRoom);

export const pageQuery = graphql`
  query SingleRoomData($id: String!) {
    room: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      seo {
        title
        metaDesc
        metaKeywords
      }
      roomAttributes {
        gallery {
          ...GalleryFields
        }
        amenities
        roomType
        prices {
         ...PricesFields
        }
      }
      featuredImage {
        ...FeaturedImageFields
      }
    }
  }
`;
