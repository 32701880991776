import React, { FC } from 'react';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Rooms } from 'types';
import { isTruthy, getLocalizedPath } from 'utils/methods';

import './roomsList.css';

interface Props {
  rooms: Rooms;
}

export const RoomsList: FC<Props> = props => {
  const intl = useIntl();
  return (
    <div className="all-items grid gap-4 grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4">
      {props.rooms.map(post => {
        const postImage = post.featuredImage?.node;
        const imageFile = postImage?.localFile?.childImageSharp;
        const prices = Object.values(post.roomAttributes?.prices || {}).map(price => price?.priceFrom).filter(isTruthy);
        const priceFrom = prices.length ? Math.min(...prices) : null;
        return (post.uri && imageFile) ? (
          <div
            key={post.uri}
            className="item-wrapper overflow-hidden h-200px"
            // itemScope
            // itemType="http://schema.org/Room"
          >
            <Link
              to={getLocalizedPath(intl.locale, post.uri)}
              className="block w-full h-full relative text-white overflow-hidden"
            >
              <GatsbyImage
                className="h-200px transition-all duration-150 ease-linear transform hover:scale-110"
                image={imageFile.gatsbyImageData}
                alt={postImage?.altText || ''}
              />
              <div className="absolute pt-5px pb-10px pl-15px pr-15px w-full text-white bottom-0 bg-gradient-to-b from-transparent to-black">
                <h2 className="text-17px mt-15px mb-5px font-normal">
                  {post.title}
                </h2>
                <div className="italic text-13px pt-4px pb-4px">
                  {post.roomAttributes?.shortDescription || ''}
                </div>
              </div>
              {priceFrom && (
                <div className="absolute top-10px right-10px bg-red-600 text-white text-14px italic pt-3px pb-3px pl-5px pr-5px z-10 rounded-tl-lg rounded-br-lg">
                  <FormattedMessage
                    id="price_from"
                    defaultMessage="nuo {price} €"
                    values={{
                      price: priceFrom
                    }}
                  />
                </div>
              )}
            </Link>
          </div>
        ) : null;
      })}
    </div>
  );
};