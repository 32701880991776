import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import classNames from 'classnames';

interface Props {
  title: MessageDescriptor;
  className?: string;
}
  
export const InfoWrapper: FC<Props> = props => {
  return (
    <div className={classNames(props.className)}>
      <h2 className="text-20px font-normal pt-20px pb-20px">
        <FormattedMessage {...props.title} />
      </h2>
      {props.children}
    </div>
  );
};