import React, { FC } from 'react';
import classNames from 'classnames';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Gallery, GalleryImage } from '@designus/react-simple-gallery/preact';
  
interface Props {
  image?: GalleryImage<IGatsbyImageData> | null;
  className?: string;
}
  
export const SingleImage: FC<Props> = props => {
  const { image, className = '' } = props;
  return image ? (
    <div className={classNames('hidden w-220px sm:w-250px ml-15px xs:block xs:float-right cursor-pointer', className)}>
      <div className="bg-white p-5px ">
        <Gallery
          className="block"
          images={[image]}
          renderThumbImage={image => (
            <GatsbyImage
              image={image.thumb}
              alt={image.alt || ''}
            />
          )}
          renderFullImage={image => (
            <GatsbyImage
              image={image.full}
              alt={image.alt || ''}
              objectFit="contain"
            />
          )}
        />
        {image.caption && (
          <div className="caption text-center italic pt-5px">
            {image.caption}
          </div>
        )}
      </div>
    </div>
  ) : null;
};